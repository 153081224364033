import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
  CancelOutlined as CancelCollect,
  MenuOpen as OpenCollect,
  Print as PrinCollect,
  Schedule,
  Done,
  DoneAll,
  Block,
  MoreVert as Edit,
} from '@material-ui/icons'
import { Typography, Box, Divider } from '@material-ui/core'

import {
  cancelCollectRequest,
  reOpenCollectRequest,
  displayForEditCollect,
} from '@/store/modules/users/collections/reducer'
import formatter from '@/services/formatter'

import { createCollectForPrint } from '@/services/collects'
import { Button, ChipStatus } from '@/components'
import { ButtonsContainer, HeaderContainer } from '../styles'

// ------------------------------------------------------------------------

const STATUS = {
  new: 1,
  confirmed: 2,
  loaded: 3,
  cancelled: 4,
}

const Icons = {
  [STATUS.new]: <Schedule color="inherit" fontSize="small" />,
  [STATUS.confirmed]: <Done color="inherit" fontSize="small" />,
  [STATUS.loaded]: <DoneAll color="inherit" fontSize="small" />,
  [STATUS.cancelled]: <Block color="inherit" fontSize="small" />,
}

// ------------------------------------------------------------------------

export function IssuedCollectsTableHeader(props) {
  const { data: coleta, onPrint } = props

  const coletaHeader = coleta.header

  const dispatch = useDispatch()

  function handleReopen() {
    dispatch(reOpenCollectRequest(coletaHeader))
  }

  function handlePrint() {
    onPrint(createCollectForPrint(coletaHeader.id_coleta, coleta.items))
  }

  function handleCancel() {
    dispatch(cancelCollectRequest(coletaHeader))
  }

  function handleEdit() {
    dispatch(displayForEditCollect(coletaHeader))
  }

  const showObservationsSection =
    coletaHeader.vpt_obs?.trim() || coletaHeader.vpt_data_cancelamento

  const isWaitingConfirmation = coletaHeader.status?.id === STATUS.new

  const isEditable = [STATUS.new, STATUS.confirmed].includes(
    coletaHeader.status?.id
  )

  const cancelObservation = `${formatter(
    coletaHeader.vpt_data_cancelamento
  ).toSimpleDate()} - ${coletaHeader.vpt_motivo_cancelamento}`

  return (
    <HeaderContainer>
      {/* === COLETA === */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gridGap="1rem">
          <Typography
            color="textPrimary"
            variant="h6"
            style={{ fontWeight: 600 }}
          >
            {coletaHeader.id_coleta}
          </Typography>
        </Box>

        <Box display="flex" gridGap="1rem">
          <TextBlock
            label="Emissão:"
            value={formatter(coletaHeader.vpt_data_emissao).toSimpleDate()}
          />
          <TextBlock
            label="Coleta:"
            value={formatter(coletaHeader.vpt_dcoleta).toSimpleDate()}
          />
        </Box>
      </Box>

      {/* === TRANSPORTE === */}
      <Box display="flex" alignItems="center" gridGap="1.5rem" mb={1}>
        <Typography variant="body2" component="span">
          {formatter(coletaHeader.vpt_cod_motorista).toCPF()}
        </Typography>

        <Divider orientation="vertical" flexItem />

        <Typography variant="body2" component="span">
          {coletaHeader.vpt_motorista}
        </Typography>

        <Divider orientation="vertical" flexItem />

        <Typography variant="body2" component="span">
          {coletaHeader.vpt_placa}
        </Typography>
      </Box>

      {/* === OBSERVAÇÕES === */}
      <Box display={showObservationsSection ? 'block' : 'none'}>
        <Typography component="p" variant="body2" gutterBottom>
          {coletaHeader.vpt_obs}
        </Typography>

        {coletaHeader.vpt_data_cancelamento && (
          <Typography component="p" variant="body2" color="error">
            {cancelObservation}
          </Typography>
        )}
      </Box>

      {/* === ACTIONS === */}
      <Box display="flex" justifyContent="space-between" marginTop="1rem">
        <ButtonsContainer>
          <Button
            label="Imprimir"
            variant="contained"
            size="small"
            color="primary"
            startIcon={<PrinCollect />}
            className="print"
            onClick={handlePrint}
          />
          {isWaitingConfirmation && (
            <Button
              label="Reabrir"
              variant="contained"
              size="small"
              color="primary"
              className="reopen"
              startIcon={<OpenCollect />}
              onClick={handleReopen}
            />
          )}
          {isWaitingConfirmation && (
            <Button
              label="Cancelar"
              variant="contained"
              size="small"
              color="primary"
              startIcon={<CancelCollect />}
              className="cancel"
              onClick={handleCancel}
            />
          )}
          {isEditable && (
            <Button
              label="Alterar"
              onClick={handleEdit}
              color="primary"
              variant="contained"
              size="small"
              startIcon={<Edit />}
            />
          )}
        </ButtonsContainer>

        {coletaHeader.status && (
          <ChipStatus
            icon={Icons[coletaHeader.status.id]}
            label={coletaHeader.status.descr}
            background={coletaHeader.status.color}
            className="large"
            variant="default"
          />
        )}
      </Box>
    </HeaderContainer>
  )
}

IssuedCollectsTableHeader.propTypes = {
  onPrint: PropTypes.func,
  data: PropTypes.object,
}

// ------------------------------------------------------------------------

function TextBlock({ label, value, ...props }) {
  return (
    <Typography
      {...props}
      variant="body2"
      component="div"
      style={{
        display: 'flex',
        gap: '0.5rem',
      }}
    >
      <span style={{ fontWeight: 600, textTransform: 'uppercase' }}>
        {label}
      </span>

      <span>{value}</span>
    </Typography>
  )
}
